const getForm = (
  src: string,
  formData: { [key: string]: string | number | undefined },
): HTMLFormElement => {
  const form = document.createElement('form')
  Object.keys(formData).forEach((key) => {
    const child = document.createElement('input')
    child.name = key
    if (key !== undefined) {
      child.value = `${formData[key]}`
    } else {
      child.value = ''
    }
    child.type = 'hidden'
    form.appendChild(child)
  })
  form.action = src
  form.method = 'post'

  return form
}

/**
 * form 형태로 데이터 전송하는 경우 사용
 * @param src
 * @param formData
 * @param timeout
 */
export const formFetch = (
  src: string,
  formData: { [key: string]: string | number | undefined },
  timeout = 3000,
): Promise<void> =>
  new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe')
    iframe.id = 'form_fetch_temp_iframe'
    iframe.name = 'form_fetch_temp_iframe'
    iframe.setAttribute('style', 'display:none')
    const form = getForm(src, formData)
    form.target = 'form_fetch_temp_iframe'
    document.body.appendChild(iframe)
    document.body.appendChild(form)
    form.submit()
    let resolved = false
    iframe.onload = (): void => {
      if (!resolved) {
        document.body.removeChild(iframe)
        document.body.removeChild(form)
        resolved = true
        resolve()
      }
    }
    window.setTimeout(() => {
      if (!resolved) {
        document.body.removeChild(iframe)
        document.body.removeChild(form)
        resolved = true
        reject(new Error('timeout'))
      }
    }, timeout)
  })

/**
 * form submit으로 페이지 이동시 사용
 * @param src
 * @param formData
 * @param toHiddenIframe
 */
export const formSubmit = (
  src: string,
  formData: { [key: string]: string | number | undefined },
  toHiddenIframe = false,
): void => {
  const form = getForm(src, formData)
  if (toHiddenIframe) {
    const iframeId = 'form_submit_temp_iframe_' + Date.now()
    const iframe = document.createElement('iframe')
    iframe.id = iframeId
    iframe.name = iframeId
    iframe.setAttribute('style', 'display:none')
    form.target = iframeId
    document.body.appendChild(iframe)
  }

  document.body.appendChild(form)
  form.submit()
}
