import React from 'react'
import domains from '~/data/domains'

const EmptyUnitCouponItem = () => {
  return (
    <div className="box__content-empty">
      <h3 className="text__title-empty">
        <embed
          type="image/svg+xml"
          className="box__animation"
          src={`${domains.PICS}/pc/single/kr/common/neutral_face.svg`}
        />
        보유하신 쿠폰 중 <br /> 조건에 만족하는 쿠폰이 없습니다.
      </h3>
    </div>
  )
}

export default React.memo(EmptyUnitCouponItem)
