import React from 'react'
import EstimateSheetContainer from '~/cart/gmarket/ko/pc/containers/EstimateSheetContainer'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'

const LayerEstimateSheet = ({
  layerId,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()

  return (
    <div className="nv-layer" id={layerId} style={{ display: 'block' }}>
      <div className="nv-layer-bg" onClick={onCloseLayer()} />
      <div className="nv-layer-cont">
        <EstimateSheetContainer onClickClose={onCloseLayer()} />
      </div>
    </div>
  )
}

export default LayerEstimateSheet
