import React, { useEffect, useRef } from 'react'
import CustomAnchor from '~/lib/components/CustomAnchor'
import GatewayIframe from '~/lib/components/GatewayIframe'
import { IframeGatewayProps } from '~/types/common'

const ExpressShopLayer = ({
  iframeKey,
  title,
  src,
  formData,
  onLoadIframe,
  iframeAttributes,
  onClickClose,
}: IframeGatewayProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null)
  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [])

  return (
    <div>
      <div
        className="nv-layer"
        id="popup_expressshop_bridge"
        style={{ display: 'block' }}
      >
        <div className="nv-layer-bg" onClick={onClickClose} />
        <div className="nv-layer-cont">
          <div className="ly_wrap">
            <GatewayIframe
              id={`${iframeKey}_iframe`}
              name={`${iframeKey}_iframe`}
              title={title}
              src={formData ? undefined : src}
              frameBorder="0"
              width="750px"
              height="660px"
              scrolling="no"
              className="ifrm_bridge"
              onLoad={onLoadIframe}
              {...iframeAttributes}
            />
            <CustomAnchor onClick={onClickClose} className="ly_wrap_close">
              <span className="icon sprite__cart">닫기</span>
            </CustomAnchor>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpressShopLayer
