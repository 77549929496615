import React, { useEffect, useMemo } from 'react'
import HeaderHelper from '~/lib/header-helper'

const Footer = ({ isHidden }: { isHidden?: boolean }): JSX.Element => {
  const headerHelper = useMemo(() => new HeaderHelper(), [])

  useEffect(() => {
    headerHelper.renderFooter()
    return (): void => {
      headerHelper.removeFooter()
    }
  }, [headerHelper])

  return <div id="footer" style={isHidden ? { display: 'none' } : undefined} />
}

export default Footer
