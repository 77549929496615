import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { goInitialPath } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { IframeGatewayProps } from '~/types/common'
const WindowOpen = ({
  title,
  src,
  isNotDimmed,
  onClickClose,
}: IframeGatewayProps): JSX.Element => {
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  if (isNotDimmed) {
    dispatch(goInitialPath())
  }

  useEffect(() => {
    const popupX = Math.round(window.screenX + window.outerWidth / 2 - 410 / 2)
    const popupY = Math.round(window.screenY + window.outerHeight / 2 - 640 / 2)
    window.open(src, title, `width=410,height=640,top=${popupY},left=${popupX}`)
  }, [src, title])

  return (
    <div
      className="box__layer box__layer-iframe"
      id="box__layer-iframe"
      role="dialog"
      onClick={onClickClose}
    >
      {!isNotDimmed && <div className="dimmed" onClick={onClickClose} />}
    </div>
  )
}

export default WindowOpen
