import React, { useEffect, useRef } from 'react'
import GatewayForm from '~/lib/components/GatewayForm'
import GatewayIframe from '~/lib/components/GatewayIframe'
import { IframeGatewayProps } from '~/types/common'

const IframeLayer = ({
  iframeKey,
  title,
  src,
  formData,
  onLoadIframe,
  iframeAttributes,
  onClickClose,
}: IframeGatewayProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null)
  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [])

  return (
    <div
      className="box__layer box__layer-iframe"
      id="box__layer-iframe"
      role="dialog"
      onClick={onClickClose}
    >
      <div className="box__iframe">
        <GatewayIframe
          id={`${iframeKey}_iframe`}
          name={`${iframeKey}_iframe`}
          title={title}
          src={formData ? undefined : src}
          frameBorder="0"
          onLoad={onLoadIframe}
          {...iframeAttributes}
        />
        {formData && (
          <GatewayForm
            target={`${iframeKey}_iframe`}
            src={src}
            formRef={formRef}
            formData={formData}
          />
        )}
      </div>
      <div className="dimmed" onClick={onClickClose} />
    </div>
  )
}

export default IframeLayer
