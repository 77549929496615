import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LayerSmileClubOneClickJoin from '~/cart/gmarket/ko/common/containers/Layer/LayerSmileClubOneClickJoin'
import LayerJoinSmileClubForSmileDelivery from '~/cart/gmarket/ko/mobile/containers/Layer/LayerJoinSmileClubForSmileDelivery'
import LayerConfirmAuthentication from '~/cart/gmarket/ko/pc/containers/Layer/LayerConfirmAuthentication'
import LayerConfirmIncomeDutyItemOrderType from '~/cart/gmarket/ko/pc/containers/Layer/LayerConfirmIncomeDutyItemOrderType'
import LayerConfirmMaxInstallOrder from '~/cart/gmarket/ko/pc/containers/Layer/LayerConfirmMaxInstallOrder'
import LayerConfirmOrderExceptSomeItems from '~/cart/gmarket/ko/pc/containers/Layer/LayerConfirmOrderExceptSomeItems'
import LayerCountrySelector from '~/cart/gmarket/ko/pc/containers/Layer/LayerCountrySelector'
import LayerEstimateSheet from '~/cart/gmarket/ko/pc/containers/Layer/LayerEstimateSheet'
import LayerNudgingCartCountExtended from '~/cart/gmarket/ko/pc/containers/Layer/LayerNudgingCartCountExtended'
import LayerOverseaShippingNoti from '~/cart/gmarket/ko/pc/containers/Layer/LayerOverseaShippingNoti'
import LayerUnitCouponBox from '~/cart/gmarket/ko/pc/containers/Layer/LayerUnitCouponBox'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import { EnumLayerType, LayerData } from '~/cart/modules/view/types'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import ComplexDataStore from '~/lib/complex-data-store'
import logRender from '~/lib/log-render'
import UXEHelper from '~/lib/uxe-helper'

const LayerContainer = ({ layer }: { layer: LayerData }): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const layerId =
    layer.type === EnumLayerType.UnitCouponBox
      ? 'layer_couponbox'
      : layer.type === EnumLayerType.SmileClubOneClickJoin
      ? 'layer_onclick-smileclub'
      : `xo_layer_${layer.key}`

  const isUseUXEFunction = ![
    EnumLayerType.EstimateSheet,
    EnumLayerType.UnitCouponBox,
    EnumLayerType.CountrySelector,
    EnumLayerType.JoinSmileClubForSmileDelivery,
    EnumLayerType.SmileClubOneClickJoin,
    EnumLayerType.NudgingClubOneClick,
    EnumLayerType.ConfirmOrderExceptSomeItems,
    EnumLayerType.ConfirmIncomeDutyItemOrderType,
    EnumLayerType.ConfirmAuthentication,
  ].includes(layer.type)

  const layerDetailData = ComplexDataStore.get('LayerDetailData', layer.key)
  useEffect(() => {
    if (layerDetailData && isUseUXEFunction) {
      UXEHelper.openDimmedLayer(`#${layerId}`, layerDetailData.triggerElement)
    } else if (layerDetailData) {
      try {
        $(`#${layerId}`).focus()
      } catch (e) {
        // DO NOTHING
      }
      return (): void => {
        layerDetailData.triggerElement.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseLayerAsync = useCallback(
    (closeImmediately = false): Promise<void> =>
      new Promise<void>((resolve) => {
        if (isUseUXEFunction) {
          UXEHelper.closeDimmedLayer(`#${layerId}`)
        }
        const key = window.setTimeout(
          () => {
            dispatch(closeLayer(layer.key))
            resolve()
          },
          closeImmediately || !isUseUXEFunction ? 0 : 500,
        )
        return (): void => {
          window.clearTimeout(key)
        }
      }),
    [dispatch, isUseUXEFunction, layer.key, layerId],
  )

  const onCloseLayer = useCallback(
    (closeImmediately = false) =>
      async (): Promise<void> => {
        await onCloseLayerAsync(closeImmediately)
      },
    [onCloseLayerAsync],
  )

  if (!layerDetailData) {
    dispatch(closeLayer(layer.key))
    return <></>
  }

  switch (layer.type) {
    case EnumLayerType.UnitCouponBox:
      return (
        <LayerUnitCouponBox
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          detailData={layerDetailData.unitCouponBox}
        />
      )
    case EnumLayerType.CountrySelector:
      return (
        <LayerCountrySelector
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.JoinSmileClubForSmileDelivery:
      return (
        <LayerJoinSmileClubForSmileDelivery
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.SmileClubOneClickJoin:
      return (
        <LayerSmileClubOneClickJoin
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.ConfirmOrderExceptSomeItems:
      return (
        <LayerConfirmOrderExceptSomeItems
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          onCloseLayerAsync={onCloseLayerAsync}
          detailData={layerDetailData.confirmOrderExceptSomeItems}
        />
      )
    case EnumLayerType.ConfirmIncomeDutyItemOrderType:
      return (
        <LayerConfirmIncomeDutyItemOrderType
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          onCloseLayerAsync={onCloseLayerAsync}
          detailData={layerDetailData.confirmIncomeDutyItemOrderType}
        />
      )
    case EnumLayerType.ConfirmAuthentication:
      return (
        <LayerConfirmAuthentication
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          onCloseLayerAsync={onCloseLayerAsync}
          detailData={layerDetailData.confirmAuthentication}
        />
      )
    case EnumLayerType.EstimateSheet:
      return (
        <LayerEstimateSheet
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.OverseaPackageNoti:
      return (
        <LayerOverseaShippingNoti
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
          onCloseLayerAsync={onCloseLayerAsync}
          detailData={layerDetailData.confirmOverseaShippingNotiType}
        />
      )
    case EnumLayerType.ConfirmMaxInstallOrder:
      return (
        <LayerConfirmMaxInstallOrder
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
    case EnumLayerType.NudgingCartCountExtended:
      return (
        <LayerNudgingCartCountExtended
          layerId={layerId}
          layerKey={layer.key}
          onCloseLayer={onCloseLayer}
        />
      )
  }

  return <></>
}

export default React.memo(LayerContainer, (l, r) => l.layer.key === r.layer.key)
