import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import ExpressShopLayer from '~/cart/gmarket/ko/pc/components/Gateway/ExpressShopLayer'
import IframeLayer from '~/cart/gmarket/ko/pc/components/Gateway/IframeLayer'
import SmallIframeLayer from '~/cart/gmarket/ko/pc/components/Gateway/SmallIframeLayer'
import { goInitialPath } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import ComplexDataStore from '~/lib/complex-data-store'
import WindowOpenGateway from '~/lib/components/WindowOpenGateway'
import EnumConverter from '~/lib/enum-converter'
import logRender from '~/lib/log-render'
import simpleSwitch from '~/lib/simple-switch'
import { IframeGatewayProps } from '~/types/common'
import { EnumGatewayType } from '~/types/enums'

const GatewayContainer = ({
  match,
}: RouteComponentProps<{
  key: string
  type: string
}>): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const gatewayType = EnumConverter.toEnum(
    EnumGatewayType,
    parseInt(match.params.type),
    EnumGatewayType.NormalStaticPage,
  )

  const data = ComplexDataStore.get('GatewayData', match.params.key)
  useEffect(() => {
    return (): void => {
      if (data) {
        data.triggerElement.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLoadIframe = (e: React.SyntheticEvent<HTMLIFrameElement>): void => {
    if (e.currentTarget) {
      e.currentTarget.focus()
    }
  }

  const onClickClose = async (): Promise<void> => {
    dispatch(goInitialPath())
    if (data?.onResolve) {
      data.onResolve()
    }
  }

  type IframeTypes =
    | 'Small' // 410*640
    | 'Normal' // 500*800
    | 'ExpressShop' // 750*660
    | 'WindowOpen'

  const supportedIframeTypes: PartialRecord<EnumGatewayType, IframeTypes> = {
    [EnumGatewayType.NormalStaticPage]: 'Normal',
    [EnumGatewayType.SmallStaticPage]: 'Small',
    [EnumGatewayType.SmileClubGateway]: 'WindowOpen',
    [EnumGatewayType.AddressBook]: 'Normal',
    [EnumGatewayType.ExpressShop]: 'ExpressShop',
  }

  const IframeComponent = simpleSwitch<
    ((props: IframeGatewayProps) => JSX.Element) | undefined,
    IframeTypes
  >(supportedIframeTypes[gatewayType])
    .on('Normal', IframeLayer)
    .on('Small', SmallIframeLayer)
    .on('ExpressShop', ExpressShopLayer)
    .on('WindowOpen', WindowOpenGateway)
    .otherwise(undefined)

  if (supportedIframeTypes[gatewayType] === 'WindowOpen') {
    window.initialHistoryLength = window.history.length
  }

  if (data && IframeComponent) {
    return (
      <IframeComponent
        onLoadIframe={onLoadIframe}
        iframeKey={match.params.key}
        title={data.title}
        src={data.src}
        isNotDimmed={data.isNotDimmed}
        formData={data.formData}
        iframeAttributes={data.iframeAttributes || {}}
        onClickClose={onClickClose}
        isApp={false}
      />
    )
  } else {
    return <></>
  }
}

export default GatewayContainer
