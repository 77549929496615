import { map } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShippingCountryComplex } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { getShippingCountry } from '~/cart/modules/shipping/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomInput from '~/lib/components/CustomInput'
import EnumConverter from '~/lib/enum-converter'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'
import { ShippingCountryType } from '~/types/enums'

const LayerCountrySelector = ({
  layerId,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const shippingCountry = useSelector((state: RootState) =>
    getShippingCountry(state.shipping),
  )

  const [
    selectedCountryType,
    selectCountryType,
  ] = useState<ShippingCountryType>(shippingCountry.countryType)

  useEffect(() => {
    selectCountryType(shippingCountry.countryType)
  }, [shippingCountry.countryType])

  const countryList = useSelector(
    (state: RootState) => state.shipping.overseaShippingCountries,
  )

  const defaultShippingCountry: ShippingCountryType = 'SouthKorea'

  const changeCountryAndClose = useCallback(
    (countryType: ShippingCountryType): void => {
      dispatch(setShippingCountryComplex(countryType)).then((isSuccess) => {
        if (isSuccess) {
          onCloseLayer()()
        }
      })
    },
    [dispatch, onCloseLayer],
  )

  const onClickCountry = useCallback(
    (countryType: ShippingCountryType) => (): void => {
      changeCountryAndClose(countryType)
    },
    [changeCountryAndClose],
  )
  const onChangeCountry = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const countryType = EnumConverter.toUnionType<ShippingCountryType>(
        e.currentTarget.value,
      )
      selectCountryType(countryType)
    },
    [],
  )
  const onClickConfirmButton = useCallback(() => {
    changeCountryAndClose(selectedCountryType)
  }, [changeCountryAndClose, selectedCountryType])

  return (
    <div id={layerId} className="nv-layer" style={{ display: 'block' }}>
      <div className="nv-layer-bg" onClick={onCloseLayer()} />
      <div className="nv-layer-cont">
        <div className="box__layer-order-oversea">
          <div className="box__layer-header">
            <h2 className="text__header">배송 할 국가 선택</h2>
            <button
              type="button"
              className="sprite__cart--before button__close"
              onClick={onCloseLayer()}
            >
              <span className="for-a11y">레이어 닫기</span>
            </button>
          </div>
          <div className="box__layer-body">
            <div className="box__order-oversea">
              {shippingCountry.countryType !== defaultShippingCountry && (
                <div className="box__destination">
                  <span className="text__destination">
                    {shippingCountry.countryName}
                  </span>
                  <button
                    type="button"
                    className="button__change"
                    onClick={onClickCountry(defaultShippingCountry)}
                  >
                    국내배송(한국)으로 변경하기
                  </button>
                </div>
              )}
              <ul className="list__order-oversea">
                {map(countryList, (country, idx) => (
                  <li key={idx} className="list-item">
                    <CustomInput<ShippingCountryType>
                      type="radio"
                      id={country.countryCode}
                      name="ship_country"
                      className="form__radio sprite__cart"
                      value={country.countryType}
                      checked={selectedCountryType === country.countryType}
                      onChange={onChangeCountry}
                    />
                    <label
                      htmlFor={country.countryCode}
                      className="text__label"
                    >
                      {country.countryName}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            <div className="box__button-wrap">
              <button
                type="button"
                className="button button__cancel"
                onClick={onCloseLayer()}
              >
                취소하기
              </button>
              <button
                type="button"
                className="button button__comfirm"
                onClick={onClickConfirmButton}
              >
                선택하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayerCountrySelector
