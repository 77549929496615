import React, { useEffect, useMemo } from 'react'
import { fetchGetUserInfoForGmarketHeader } from '~/apis/buyer'
import HeaderHelper from '~/lib/header-helper'

const Header = ({ isHidden }: { isHidden?: boolean }): JSX.Element => {
  const headerHelper = useMemo(() => new HeaderHelper(), [])

  useEffect(() => {
    fetchGetUserInfoForGmarketHeader()
      .then((userInfo) => {
        headerHelper.renderHeader('', userInfo)
      })
      .catch(() => {
        headerHelper.renderHeader('', { type: 'unknown' })
      })
    return (): void => {
      headerHelper.removeHeader()
    }
  }, [headerHelper])

  return <div id="header" style={isHidden ? { display: 'none' } : undefined} />
}

export default Header
