import moment from 'moment'
import React, { useCallback, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useSelector } from 'react-redux'
import { ItemOption } from '~/cart/modules/cart/types'
import {
  getCartUnitPriceInfo,
  getSelectedCartUnitList,
  getTotalSummary,
  RootState,
} from '~/cart/modules/reducers'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formSubmit } from '~/lib/form-fetch'
import { currencyAmount } from '~/lib/formatter'
import logRender from '~/lib/log-render'

const getOptionText = (option: ItemOption): string => {
  if (option.optionType === 'Calculation') {
    return option.optionEntries.reduce((result, entry) => {
      if (result) {
        return `${result} x ${entry.name} (${entry.value}${option.calculationUnit})`
      } else {
        return `${entry.name} (${entry.value}${option.calculationUnit})`
      }
    }, '')
  } else {
    return option.optionEntries.reduce((result, entry) => {
      if (result) {
        return result + ` + ${entry.value}`
      } else {
        return entry.value
      }
    }, '')
  }
}

const EstimateSheetContainer = ({
  onClickClose,
}: {
  onClickClose: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  logRender()
  const estimateDivRef = useRef<HTMLDivElement>(null)
  const buyerInfoTableRef = useRef<HTMLTableElement>(null)
  const ebayInfoTableRef = useRef<HTMLTableElement>(null)
  const bodyTableRef = useRef<HTMLTableElement>(null)
  const summaryTableRef = useRef<HTMLTableElement>(null)
  const excelFormRef = useRef<HTMLFormElement>(null)

  const summaryInfo = useSelector(
    (state: RootState) => getTotalSummary(state),
    shallowEqual,
  )
  const cartUnitList = useSelector(
    (state: RootState) =>
      getSelectedCartUnitList(state).map((x) => ({
        ...x,
        price: getCartUnitPriceInfo(state, x.cartUnitId),
      })),
    isEqual,
  )
  const buyerName = useSelector((state: RootState) => state.buyer.buyerName)

  const onClickPrint = useCallback(() => {
    const printWindow = window.open(
      '',
      '견적서',
      'width=520,height=1000,scrollbars=yes,status=no',
    )
    if (!printWindow || !estimateDivRef.current) {
      return
    }
    printWindow.document.write('<html><head>')
    printWindow.document.write(
      `<title>G마켓_견적서_${moment().format('YYYYMMDD_HHmmSS')}</title>`,
    )
    printWindow.document.write(
      `<link rel="stylesheet" href="${
        domains.SCRIPT + '/pc/css/application/kr/cart/app.css'
      }"/>`,
    )
    printWindow.document.write(
      '<style>.do_not_print{display:none!important;}</style>',
    )
    printWindow.document.write('</head><body>')
    printWindow.document.write(estimateDivRef.current.outerHTML)
    printWindow.document.write('</body>')
    printWindow.document.close()
    printWindow.focus()
    setTimeout(() => {
      printWindow.print()
      setTimeout(() => {
        printWindow.close()
      }, 500)
    }, 500)
  }, [])

  const onClickSaveExcel = useCallback(() => {
    if (
      !buyerInfoTableRef.current ||
      !ebayInfoTableRef.current ||
      !bodyTableRef.current ||
      !summaryTableRef.current
    ) {
      return
    }

    formSubmit(
      domains.CART_FE_SERVER + '/gateway/-/estimate/to-excel',
      {
        buyerInfoTable: buyerInfoTableRef.current.outerHTML,
        ebayInfoTable: ebayInfoTableRef.current.outerHTML,
        bodyTable: bodyTableRef.current.outerHTML,
        summaryTable: summaryTableRef.current.outerHTML,
      },
      true,
    )
  }, [])

  return (
    <div className="ly_wrap" id="cartEstimate">
      <div ref={estimateDivRef} className="pop-warp btn_ly_ty4">
        <div className="header">
          <h1>
            <img
              src="//pics.gmarket.co.kr/pc/ko/pay/tit_estimate.png"
              alt="견적서"
            />
          </h1>
        </div>
        <div className="content">
          <div className="con-01">
            <table className="left" ref={buyerInfoTableRef}>
              <colgroup>
                <col width="38%" />
                <col width="61%" />
              </colgroup>
              <tr>
                <th>견적일자</th>
                <td>
                  <span className="font-tahoma">
                    {moment().format('YYYY.MM.DD')}
                  </span>
                </td>
              </tr>
              <tr>
                <th className="pd">담당자</th>
                <td className="pd">
                  <strong>{buyerName} 님 귀중</strong>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>아래와 같이 견적합니다.</td>
              </tr>
            </table>

            <table className="right" ref={ebayInfoTableRef}>
              <colgroup>
                <col width="90px" />
                <col width="*" />
                <col width="18%" />
                <col width="18%" />
              </colgroup>
              <tr>
                <th>사업자 등록번호</th>
                <td colSpan={3}>
                  <span className="font-tahoma">220-81-83676 </span>
                </td>
              </tr>
              <tr>
                <th>상호명</th>
                <td>주식회사 지마켓</td>
                <th className="align-c">대표자</th>
                <td>정형권</td>
              </tr>
              <tr>
                <th>소재지</th>
                <td colSpan={3}>
                  서울시 강남구 테헤란로 152 (역삼동 강남파이낸스센터)
                </td>
              </tr>
            </table>
          </div>

          <div className="con-02">
            <table className="list" ref={bodyTableRef}>
              <colgroup>
                <col width="5%" />
                <col width="31%" />
                <col width="13%" />
                <col width="10%" />
                <col width="13%" />
                <col width="13%" />
                <col width="15%" />
              </colgroup>
              <thead>
                <tr>
                  <th className="first">번호</th>
                  <th>상품명 / 필수선택 / 추가구성</th>
                  <th>공급자명</th>
                  <th>수량</th>
                  <th>공급가액</th>
                  <th>할인금액</th>
                  <th>공급합계</th>
                </tr>
              </thead>

              <tbody>
                {cartUnitList.map((cartUnit, idx) => {
                  const optionCount = cartUnit.item.options.length > 0 ? 1 : 0
                  const additionCount = cartUnit.item.additions
                    ? cartUnit.item.additions.length
                    : 0
                  const rowCount = optionCount + additionCount + 1

                  const optionText = cartUnit.item.options
                    .map((option) => getOptionText(option))
                    .join(' / ')

                  return (
                    <>
                      <tr>
                        <td className="first" rowSpan={rowCount}>
                          {idx + 1}
                        </td>
                        <td className="align-l">
                          {cartUnit.item.itemName}
                          {cartUnit.cartUnitType === 'ExpressShop' &&
                            cartUnit.expressShopBranch && (
                              <span>
                                <br />
                                <br />
                                배송점포:
                                {`${
                                  cartUnit.expressShopBranch.branchName
                                }(+${currencyAmount(
                                  cartUnit.expressShopBranch
                                    .branchAdditionalPrice,
                                )}원)`}
                              </span>
                            )}
                        </td>
                        <td rowSpan={rowCount}>{cartUnit.seller.sellerName}</td>
                        <td rowSpan={optionCount + 1}>
                          <span className="font-tahoma1">
                            {cartUnit.quantity}
                          </span>
                        </td>
                        <td rowSpan={optionCount + 1}>
                          <span className="font-tahoma2">
                            {currencyAmount(
                              cartUnit.price.cartUnitItemPrice -
                                cartUnit.price.cartUnitAdditionsTotalPrice,
                            )}
                            원
                          </span>
                        </td>
                        <td rowSpan={rowCount}>
                          <span className="font-tahoma3">
                            {currencyAmount(
                              cartUnit.price.cartUnitDiscountPrice +
                                cartUnit.price.cartUnitCouponPrice,
                            )}
                            원
                          </span>
                        </td>
                        <td rowSpan={rowCount}>
                          <span className="font-tahoma4">
                            {currencyAmount(cartUnit.price.cartUnitPrice)}원
                          </span>
                        </td>
                      </tr>
                      {cartUnit.item.options.length > 0 && (
                        <tr>
                          <td
                            className="rowline align-l"
                            style={{ textAlign: 'left' }}
                          >
                            {optionText}
                          </td>
                        </tr>
                      )}
                      {cartUnit.item.additions.length > 0 &&
                        cartUnit.item.additions.map((addition, idx) => {
                          const additionText = `[추가구성] ${addition.name} : ${addition.value}`
                          return (
                            <tr key={`addition_${idx}`}>
                              <td
                                className="rowline align-l"
                                style={{ textAlign: 'left' }}
                              >
                                {additionText}
                              </td>
                              <td>
                                <span className="font-tahoma1">
                                  {addition.quantity}
                                </span>
                              </td>
                              <td>
                                <span className="font-tahoma2">
                                  {currencyAmount(
                                    addition.price * addition.quantity,
                                  )}
                                  원
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                    </>
                  )
                })}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={4} className="first">
                    합계
                  </td>
                  <td>
                    <span className="font-tahoma2">
                      {currencyAmount(summaryInfo.totalItemPrice)}원
                    </span>
                  </td>
                  <td>
                    <span className="font-tahoma3">
                      {currencyAmount(summaryInfo.totalNegativePrice)}원
                    </span>
                  </td>
                  <td>
                    <span className="font-tahoma4">
                      {currencyAmount(
                        summaryInfo.totalItemPrice -
                          summaryInfo.totalNegativePrice,
                      )}
                      원
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <p className="txt-info">
              * 오픈마켓의 특성상 가격이 변동될 수 있으므로 빠른 결제 바랍니다.
              <br />* 해외 배송 시에 발생하는 예상해외배송비는 견적서에 포함되지
              않습니다.
            </p>
            <table className="sum" ref={summaryTableRef}>
              <tr>
                <th>배송비</th>
                <td>
                  <span className="font-tahoma1">
                    {currencyAmount(summaryInfo.totalShippingFee)}원
                  </span>
                </td>
              </tr>
              <tr>
                <th className="pd">총 구매금액</th>
                <td className="pd">
                  <span className="font-tahoma4">
                    {currencyAmount(summaryInfo.totalPrice)}원
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div className="btn do_not_print">
            <CustomAnchor onClick={onClickPrint}>
              <img
                src="//pics.gmarket.co.kr/pc/ko/pay/btn_estimate_01.png"
                alt="인쇄"
              />
            </CustomAnchor>
            <CustomAnchor onClick={onClickSaveExcel}>
              <img
                src="//pics.gmarket.co.kr/pc/ko/pay/btn_estimate_02.png"
                alt="저장"
              />
            </CustomAnchor>
          </div>
        </div>
        <CustomAnchor onClick={onClickClose} className="l_icon ly_close">
          견적서 닫기
        </CustomAnchor>
      </div>
      <form ref={excelFormRef} method="post" />
      <iframe
        style={{ display: 'none' }}
        id="DownloadExcelFrame"
        name="DownloadExcelFrame"
      />
    </div>
  )
}

export default EstimateSheetContainer
