import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomAnchor from '~/lib/components/CustomAnchor'
import logRender from '~/lib/log-render'
import Montelena from '~/lib/montelena'
import { LayerProps } from '~/types/common'
import { ExceptionItemType } from '~/types/enums'

type LayerOrderExceptSomeItemsProp = LayerProps & {
  onCloseLayerAsync: (closeImmediately?: boolean) => Promise<void>
  detailData?: {
    showingItems: {
      itemNo: string
      itemName: string
      itemImageUrl?: string
      exceptionItemType: ExceptionItemType
    }[]
    text1: string
    text2: string
    text3: string
    subText: string
    buttonText: string
    onConfirm: (isConfirm: boolean) => void
  }
}

const LayerOrderExceptSomeItems = ({
  layerId,
  layerKey,
  onCloseLayerAsync,
  detailData,
}: LayerOrderExceptSomeItemsProp): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const onClickConfirmButton = useCallback(
    (isConfirm: boolean) => async (): Promise<void> => {
      await onCloseLayerAsync(true)
      if (detailData) {
        detailData.onConfirm(isConfirm)
      }
    },
    [detailData, onCloseLayerAsync],
  )

  const isSmileFreshOrder = detailData?.showingItems.some(
    (item) => item.exceptionItemType === 'SmileFresh',
  )

  const isInstallOrder = detailData?.showingItems.some(
    (item) => item.exceptionItemType === 'Install',
  )

  useEffect(() => {
    if (areaCodes.SHOW_LAYER_ORDER_EXCEPT_SOME_ITEMS) {
      Montelena.logImpression(
        'IMP_VI',
        areaCodes.SHOW_LAYER_ORDER_EXCEPT_SOME_ITEMS,
        // eslint-disable-next-line @typescript-eslint/camelcase
        { order_type: isSmileFreshOrder ? 'SmileFresh' : 'ETC' },
        'LayerOrderExceptSomeItems',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!detailData) {
    dispatch(closeLayer(layerKey))
    return <></>
  }

  return (
    <div id={layerId}>
      <div
        className={`${
          isInstallOrder
            ? 'box__layer box__layer-booking-install uxelayer'
            : 'nv-layer uxelayer'
        }`}
        id={`${
          isInstallOrder ? 'layer__booking-install' : 'layer-order-reject'
        }`}
        role="dialog"
        tabIndex={0}
        style={
          isInstallOrder
            ? { display: 'block', position: 'absolute' }
            : { display: 'block' }
        }
      >
        {isInstallOrder ? (
          <div className="box__layer-content">
            <div className="box__layer-header">
              <button
                type="button"
                className="button__close sprite__cart--before"
                onClick={onClickConfirmButton(false)}
              >
                <span className="for-a11y">레이어 닫기</span>
              </button>
            </div>
            <div className="box__layer-body">
              <div className="section__booking-install">
                <p className="text__message">
                  <span className="text__booking-install">
                    {detailData.text1}
                  </span>{' '}
                  {detailData.text2}
                  <br />
                  {detailData.text3}
                </p>
                <p className="text__description">{detailData.subText}</p>
                <ul className="list__order-goods">
                  {detailData.showingItems.map((itemInfo, idx) => (
                    <li className="list-item" key={idx}>
                      <div className="box__thumbnail">
                        <img
                          src={itemInfo.itemImageUrl || domains.ITEM_NO_IMAGE}
                          onError={(e): void => {
                            e.currentTarget.src = domains.ITEM_NO_IMAGE
                          }}
                          className="image__thumbnail"
                          alt={itemInfo.itemName}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="box__button">
                  <button
                    type="button"
                    className="button button__default"
                    onClick={onClickConfirmButton(true)}
                  >
                    {detailData.buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="nv-layer-cont">
            <div className="box__layer-order-reject">
              <div className="box__layer-header">
                <h2 className="text__header">
                  <em className="text__emphasis">{detailData.text1}</em>
                  {detailData.text2}
                  <br />
                  {detailData.text3}
                </h2>
                <button
                  type="button"
                  className="sprite__cart--before button__close"
                  onClick={onClickConfirmButton(false)}
                  data-montelena-acode={areaCodes.CLOSE_ORDER_EXCEPT_SOME_ITEMS}
                  data-montelena-order_type={isSmileFreshOrder}
                >
                  <span className="for-a11y">레이어 닫기</span>
                </button>
              </div>
              <div className="box__layer-body">
                <p className="text">{detailData.subText}</p>
                <ul className="list__order-reject">
                  {detailData.showingItems.map((itemInfo, idx) => (
                    <li className="list-item" key={idx}>
                      <CustomAnchor className="link__item">
                        <img
                          src={itemInfo.itemImageUrl || domains.ITEM_NO_IMAGE}
                          onError={(e): void => {
                            e.currentTarget.src = domains.ITEM_NO_IMAGE
                          }}
                          className="image"
                          alt={itemInfo.itemName}
                        />
                      </CustomAnchor>
                    </li>
                  ))}
                </ul>
                <div className="box__order">
                  <button
                    type="button"
                    className="button__order"
                    onClick={onClickConfirmButton(true)}
                    data-montelena-acode={
                      areaCodes.CONFIRM_ORDER_EXCEPT_SOME_ITEMS
                    }
                    data-montelena-order_type={isSmileFreshOrder}
                  >
                    {detailData.buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="dimmed"
        style={{ position: 'fixed' }}
        onClick={onClickConfirmButton(false)}
      />
    </div>
  )
}

export default LayerOrderExceptSomeItems
